.customField {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & select {
    &:disabled {
      opacity: 100%;
      background-color: #dddddd;
      font-style: italic;
    }
  }
}
.optionDisabled {
  &:disabled {
font-weight: 600;
font-style: italic;
font-size: 17px;
margin-top: 6px;
  }
} 
